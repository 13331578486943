import React, { ReactNode } from 'react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'

import styles from './InfoSection.module.scss'

const InfoSection = ({
  title,
  titleColour,
  description,
  colour,
  backgroundColour,
  links = [],
  button,
  disablePaddingTop,
  disablePaddingBottom
}: {
  title?: string
  titleColour?: string
  description?: string
  colour?: string
  backgroundColour?: string
  links?: Array<{
    link: string
    title: string
  }>
  button?: ReactNode
  disablePaddingTop?: boolean
  disablePaddingBottom?: boolean
}) => {
  return (
    <div className={styles.infoSection} style={{
      background: backgroundColour || '#ffffff',
      padding: `${disablePaddingTop ? '0' : '100px'} 0 ${disablePaddingBottom ? '0' : '100px'} 0`
    }}>
      <div className={`${styles.infoSectionContainer} container`}>
        <div className={styles.infoSectionTitle} style={{
          color: titleColour || '#D11A6D'
        }}>{title}</div>
        <div className={styles.infoSectionContent}>
          <div className={styles.infoSectionLeft}>
            <div className={styles.infoSectionDescription} style={{
              color: colour || '#1F1F1F'
            }}>{description}</div>
          </div>
          {(links.length > 0 || button !== undefined) &&
            <div className={styles.infoSectionRight}>
              {links.length > 0 ? (
                <div className={styles.infoSectionLinks}>
                  {links.map((x, idx) => (
                    <Link key={idx} href={x.link || '/'}>
                      <a className={styles.infoSectionLink}>
                        <div>{x.title}</div>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </a>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className={styles.infoSectionButton}>
                  {button}
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default InfoSection