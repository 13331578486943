import React, { CSSProperties, forwardRef, ReactNode, useEffect, useState } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faPlay } from '@fortawesome/free-solid-svg-icons'
import { StaticImageData } from 'next/image'
import { UrlObject } from 'url'

import Image from '../Image'

import styles from './ContentBlock.module.scss'
import ReactPlayer from 'react-player'

export type ContentBlockLayoutType = '1_1' | '1_2' | '2_1' | '1_3' | '3_1' | '1_4' | '4_1' | '4_6' | '6_4'

export type ContentBlockContent = {
  type?: 'image' | 'text' | 'video',
  src?: string | StaticImageData | { default: StaticImageData}
  thumbnail?: string | StaticImageData | { default: StaticImageData}
  width?: string | number
  height?: string | number
  fit?: CSSProperties['objectFit']
  disableRadius?: boolean
  alt?: string
  title?: string
  description?: string
  content?: ReactNode
  showMoreText?: string
  showMoreLink?: string | UrlObject
  showMoreExternal?: boolean
  largeTitle?: boolean
  hideTablet?: boolean
  hideMobile?: boolean
  textAlign?: 'left' | 'centre' | 'right'
  dir?: 'ltr' | 'rtl'
}

export type ContentBlockProps = {
  left?: ContentBlockContent
  right?: ContentBlockContent
  backgroundColour?: string
  isGroupStart?: boolean
  isGroupEnd?: boolean
  scrollId?: string
  noPadding?: boolean
  disablePaddingTop?: boolean
  disablePaddingBottom?: boolean
  layout?: ContentBlockLayoutType
  isFirst?: boolean
  isVCentered?: boolean
}

const ContentBlock = forwardRef<HTMLDivElement, ContentBlockProps>(({
  left,
  right,
  backgroundColour,
  isGroupStart,
  isGroupEnd,
  scrollId,
  noPadding,
  disablePaddingTop,
  disablePaddingBottom,
  layout,
  isFirst,
  isVCentered
}: ContentBlockProps, ref) => {
  const getWidthFromLayoutType = (column: 'left' | 'right') => {
    switch (layout) {
      case '1_1':
        return column === "left" ? 50 : 50;
      case '1_2':
        return column === "left" ? 33.33 : 66.66;
      case '2_1':
        return column === "left" ? 66.66 : 33.33;
      case '1_3':
        return column === "left" ? 25 : 75;
      case '3_1':
        return column === "left" ? 75 : 25;
      case '1_4':
        return column === "left" ? 20 : 85;
      case '4_1':
        return column === "left" ? 80 : 20;
      case '4_6':
        return column === "left" ? 40 : 60;
      case '6_4':
        return column === "left" ? 60 : 40;
    }
  }

  return (
    <div ref={ref} data-scroll-id={scrollId} className={styles.contentBlock} style={{
      background: backgroundColour || '#ffffff'
    }}>
      <div className={[
        styles.contentBlockContainer, 
        isFirst ? styles.contentBlockFirst : '',
        'container', 
        (left || {}).type !== 'image' && (right || {}).type !== 'image' ? styles.noImage : '', 
        isGroupStart ? styles.isGroupStart : '', 
        isGroupEnd ? styles.isGroupEnd : '',
        isVCentered ? styles.isVCentered : ''
      ].join(' ')} style={{
        ...(noPadding ? {
          paddingTop: 0,
          paddingBottom: 0
        } : {}),
        ...(disablePaddingTop ? {
          paddingTop: 0
        } : {}),
        ...(disablePaddingBottom ? {
          paddingBottom: 0
        } : {})
      }}>
        {left &&
          <ContentSection 
            item={left}
            className={[styles.contentBlockLeft, right ? '' : styles.isSingle].join(' ')}
            isImage={left.type === 'image' && right?.type !== 'image'}
            isVideo={left.type === 'video' && right?.type !== 'video'}
            width={right ? getWidthFromLayoutType('left'): 100}
          />
        }
        {right &&
          <ContentSection 
            item={right}
            className={[styles.contentBlockRight, left ? '' : styles.isSingle].join(' ')}
            isImage={right.type === 'image' && left?.type !== 'image'}
            isVideo={right.type === 'video' && left?.type !== 'video'}
            width={left ? getWidthFromLayoutType('right') : 100}
          />
        }
      </div>
    </div>
  )
})

const ContentSection = ({
  item,
  className,
  isImage,
  isVideo,
  width = 50
}: {
  item: ContentBlockContent
  className?: string
  isImage?: boolean
  isVideo?: boolean
  width?: number
}) => {
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)

  useEffect(() => {
    setIsVideoReady(true)
  }, [])

  return (
    <div className={[
      className, 
      isImage ? styles.isImage : (isVideo ? styles.isVideo : ''), 
      width != 50 ? styles.contentBlockCustomWidth : styles.contentBlockDefautlWidth,
      item.hideMobile ? styles.contentBlockHideMobile : '',
      item.hideTablet ? styles.contentBlockHideTablet : ''
    ].join(' ')} style={{
      width: `${width}%`
    }}>
      {item.type === 'image' ? (
        <div className={[styles.contentBlockImage, item.disableRadius ? styles.contentBlockImageNoRadius : ''].join(' ')}>
          <Image src={item.src} width={item.width || 800} height={item.height || 500} objectFit={item.fit || 'cover'} alt={item.alt} style={{
            width: item.width || 800,
            height: item.height || 500
          }} />
        </div>
      ) : (item.type === 'video' ? (
        <div style={{
          position: 'relative',
          width: '100%',
          height: 'auto',
          paddingTop: '56.25%',
          borderRadius: 12,
          overflow: 'hidden'
        }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1
          }}>
            {isVideoReady &&
              <ReactPlayer 
                url={item.src as string} 
                controls
                width="100%"
                height="100%"
                style={{
                  background: '#F1F1F1'
                }}
                playing={isVideoPlaying}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
            }
          </div>
          {!isVideoPlaying &&
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: item.thumbnail ? `url('${item.thumbnail}')` : 'none',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              zIndex: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }} onClick={() => setIsVideoPlaying(true)}>
              <div style={{
                width: 72,
                height: 72,
                borderRadius: 36,
                background: '#D11A6D',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <FontAwesomeIcon icon={faPlay} size="2x" color="#FFFFFF" />
              </div>
            </div>
          }
        </div>
      ) : (
        <div className={styles.contentBlockText}>
          {item.description &&
            <div className={styles.contentBlockTextDescription} dir={item.dir} style={item.dir ? {} : {
              textAlign: item.textAlign === 'right' ? 'right' : (item.textAlign === 'centre' ? 'center' : 'left')
            }}>{item.description === '__blank' ? <>&nbsp;</> : (item.description || '')}</div>
          }
          {item.title &&
            <div className={[styles.contentBlockTextTitle, item.largeTitle ? styles.contentBlockTextTitleLarge : ''].join(' ')} dir={item.dir} style={item.dir ? {} : {
              textAlign: item.textAlign === 'right' ? 'right' : (item.textAlign === 'centre' ? 'center' : 'left')
            }}>{item.title === '__blank' ? <>&nbsp;</> : (item.title || '')}</div>
          }
          <div className={styles.contentBlockTextContent} dir={item.dir} style={item.dir ? {} : {
            textAlign: item.textAlign === 'right' ? 'right' : (item.textAlign === 'centre' ? 'center' : 'left')
          }}>{item.content}</div>
          {item.showMoreText &&
            <Link href={item.showMoreLink}>
              <a className={styles.contentBlockTextMore} target={item.showMoreExternal ? '_blank' : undefined}>
                <div>{item.showMoreText}</div>
                <FontAwesomeIcon icon={faChevronRight} />
              </a>
            </Link>
          }
        </div>
      ))}
    </div>
  )
}

ContentBlock.displayName = 'ContentBlock'

export default ContentBlock