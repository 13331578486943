import React, { MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import styles from './Button.module.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Link from 'next/link'

const Button = ({
  title,
  backgroundColour,
  colour,
  onClick,
  className,
  style,
  icon,
  width,
  link,
  external,
  openNewTab,
  isLoading,
  isDisabled
}: {
  title: string
  backgroundColour?: string
  colour?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  className?: string
  style?: any
  icon?: IconProp
  width?: number
  link?: string
  external?: boolean
  openNewTab?: boolean
  isLoading?: boolean
  isDisabled?: boolean
}) => {
  const router = useRouter()

  const handleOnClick = evt => {
    if (onClick) {
      evt.preventDefault()
      evt.stopPropagation()

      if (!isDisabled && !isLoading) {
        onClick(evt)
      }
    }
  }

  return (
    <Link href={link || '#'} {...(openNewTab ? { target: '_blank', rel: 'noreferrer' } : {})} {...(external ? { rel: 'noreferrer' } : {})}>
      <a className={[
        styles.button, 
        isDisabled ? styles.disabled: '', 
        isLoading ? styles.loading: '', 
        title ? '' : styles.noTitle, 
        className
      ].join(' ')} style={{
        background: backgroundColour || '#D11A6D',
        color: colour || '#ffffff',
        width: width || 'auto',
        maxWidth: width ? 'none' : 300,
        ...(style || {})
      }} onClick={handleOnClick}>
        {icon && icon !== null &&
          <FontAwesomeIcon icon={icon} color={colour || '#ffffff'} />
        }
        <div>
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            title
          )}
        </div>
      </a>
    </Link>
  )
}

export default Button