import React from 'react'
import { faArrowRight, faCircleInfo, faComment, faEnvelope, faPhone, faShare } from '@fortawesome/free-solid-svg-icons'

import ContactItem from '../../components/ContactItem'
import ItemSection from '../../components/ItemSection'
import useViewport from '../../hooks/useViewport'

import Config from '../../config'

export default function HereToHelp({
  backgroundColour,
  itemColour,
  hideTitle,
  hideDescription
}: {
  backgroundColour?: string
  itemColour?: string
  hideTitle?: boolean
  hideDescription?: boolean
}) {
  const viewport = useViewport()

  return (
    <ItemSection 
      backgroundColour={backgroundColour}
      title={hideTitle ? '' : 'CONTACT US'}
      description={hideDescription ? '' : 'We are here to help'}
      size={3}
      items={[
        <ContactItem 
          key="tool" 
          title="Assessment Tool"
          description="Use our self assessment tool"
          icon={faCircleInfo}
          buttonTitle="START"
          buttonIcon={faArrowRight}
          link="/self-assessment"
          externalLink
          backgroundColour={itemColour}
        />,
        <ContactItem 
          key="refer" 
          title="Self Refer"
          description="FILL IN A SELF REFERRAL FORM"
          icon={faShare}
          buttonTitle="Self Refer"
          link="/contact"
          backgroundColour={itemColour}
        />,
        <ContactItem 
          key="contact" 
          title="Contact Us"
          description="SPEAK TO A FRIENDLY VOICE"
          icon={faComment}
          buttonTitle={viewport.width > 1500 ? 'PHONE' : undefined}
          buttonIcon={faPhone}
          link={`tel:${Config.phone}`}
          externalLink
          button2Title={viewport.width > 1500 ? 'EMAIL' : undefined}
          button2Icon={faEnvelope}
          button2Link={`mailto:${Config.email}`}
          button2ExternalLink
          backgroundColour={itemColour}
        />
      ]}
    />
  )
}