import React from 'react'

import InfoSection from '../../components/InfoSection'

export default function ReachOut({
  backgroundColour
}: {
  backgroundColour?: string
}) {
  return (
    <InfoSection 
      title="HOW WE CAN HELP"
      description="At Breakeven our trained specialists can help you on your way to recovery."
      links={[{
        title: 'learn more',
        link: '/about'
      }, {
        title: 'what is gambling related harm?',
        link: '/what-is-gambling-harm'
      }]}
      backgroundColour={backgroundColour}
    />
  )
}