import ItemSection from "../ItemSection";

export default function StatsDisplay({
  hideTitle,
  hideDescription,
  backgroundColour,
  items,
  title,
  description,
  dividerColour
}: {
  hideTitle?: boolean
  hideDescription?: boolean
  backgroundColour?: string
  items?: Array<{
    title: string
    value: string
  }>
  title?: string
  description?: string
  dividerColour?: string
}) {
  return (
    <ItemSection 
      backgroundColour={backgroundColour || '#ffffff'}
      description={hideTitle ? undefined : (title || `In ${(new Date()).getFullYear() - 1}, we supported`)}
      //description={hideDescription ? undefined : (description || `99% would recommend our services, 24hr average time from assessment to first session`)}
      size={items?.length ?? 3}
      items={(items?.length ?? 0) > 0 ? items.map((i, idx) => <StatDisplay key={idx} value={i.value} info={i.title} />) : [
        <StatDisplay key="individuals" value="15000+" info="Individuals" dividerColour={dividerColour} />,
        <StatDisplay key="trained" value="800+" info="Individuals trained in gambling harm awareness" dividerColour={dividerColour} />,
        <StatDisplay key="prison" value="2400+" info="Interventions in prisons" dividerColour={dividerColour} />,
        <StatDisplay key="recommend" value="99%" info="Would recommend our services" dividerColour={dividerColour} />,
        <StatDisplay key="response" value="24hr" isUppercaseValue={false} info="Average time from assessment to first session" dividerColour={dividerColour} />
      ]}
      addContainer={false}
      leftAlign
      itemsTopPadding={20}
      noPadding
    />
  )
}

const StatDisplay = ({
  value,
  info,
  isUppercaseValue = true,
  dividerColour
}: {
  value: string
  info: string
  isUppercaseValue?: boolean
  dividerColour?: string
}) => {
  return (
    <div style={{
      borderLeftWidth: 2,
      borderLeftStyle: 'solid',
      borderLeftColor: dividerColour || '#f1f1f1',
      paddingLeft: 20
    }}>
      <div style={{
        fontSize: 22,
        fontWeight: 600,
        textTransform: isUppercaseValue ? 'uppercase' : 'none',
        marginBottom: 10,
        color: '#D11A6D'
      }}>{value}</div>
      <div style={{
        fontSize: 16
      }}>{info}</div>
    </div>
  )
}