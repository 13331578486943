import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Hero from '../components/Hero'
import ContentBlock from '../components/ContentBlock'
import Testimonials from '../components/Testimonials'
import PageLayout from '../components/PageLayout'
import ReachOut from '../sections/ReachOut'
import HereToHelp from '../sections/HereToHelp'
import useViewport from '../hooks/useViewport'
import ItemSection from '../components/ItemSection'
import Partners from '../components/Partners'

import Config from '../config'

import styles from '../styles/Home.module.css'

import heroImage from '../public/counselling_5.jpg'
import ngtsLogo from '../public/ngts-logo.png'
import afcBanner from '../public/afc_bronze_banner.jpg'
import cpdLogo from '../public/cpd_logo.png'
import StatsDisplay from '../components/StatsDisplay'

export default function Home() {
  const viewport = useViewport()

  return (
    <PageLayout
      showAmazon={false}
    >
      <Hero 
        title="Is Gambling affecting your life?"
        description={<span>If you are concerned about yourself or a loved one contact us now for <b style={{ color: '#D11A6D' }}>free</b> support.</span>}
        image={heroImage}
        buttons={[{
          title: viewport.width < 768 ? 'SELF ASSESSMENT' : 'START SELF ASSESSMENT',
          icon: faArrowRight,
          link: '/self-assessment'
        }/*}, {
          title: 'SELF REFER',
          icon: faComment,
          link: '/contact'
        }, {
          title: 'EMAIL NOW',
          icon: faEnvelope,
          link: `mailto:${Config.email}`,
          external: true
        }*/]}
      />
      <HereToHelp backgroundColour="#ffffff" itemColour="#F1F1F1" />
      {/*<Partners />*/}
      <ContentBlock 
        left={{
          type: 'image',
          src: ngtsLogo,
          height: 360,
          fit: 'contain'
        }}
        right={{
          type: 'text',
          title: 'The NGSN',
          content: 'We are part of the National Gambling Support Network (NGSN). This is a network of organisations that all provide support for people who are impacted by gambling related harms.',
          showMoreText: 'Go to The NGSN',
          showMoreLink: 'https://www.gambleaware.org/ngsn',
          showMoreExternal: true
        }}
        backgroundColour="#F1F1F1"
        layout="1_3"
      />
      <ReachOut />
      <ContentBlock 
        left={{
          type: 'text',
          content: (
            <StatsDisplay backgroundColour="#f1f1f1" dividerColour="#d1d1d1" />
          )
        }}
        backgroundColour="#F1F1F1"
        //isGroupEnd
      />
      <Testimonials />
      <Partners 
        showTitle={false}
        partners={[{
          noLink: true,
          src: afcBanner,
          width: 405,
          height: 121
        }, {
          noLink: true,
          src: cpdLogo,
          width: 342,
          height: 121
        }]}
        backgroundColour="#ffffff"
      />
      <div style={{ height: 40 }} />
      {/*<ItemSection 
        title="STEPS TO TAKE"
        description="Self help toolkit"
        items={[
          <InfoItem 
            key="self" 
            title="Self Assessment"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            linkTitle="LEARN MORE"
          />,
          <InfoItem 
            key="recovery" 
            title="Recovery Toolkit"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            iconColour="#252627"
            linkTitle="LEARN MORE"
          />,
          <InfoItem 
            key="blocking" 
            title="Blocking Software"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            iconColour="#247B7B"
            linkTitle="LEARN MORE"
          />,
          <InfoItem 
            key="money" 
            title="Money Management"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            icon={faHandHoldingHeart}
            iconColour="#E2B95A"
            linkTitle="LEARN MORE"
          />
        ]}
      />*/}
    </PageLayout>
  )
}