import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faArrowRight, faBars, faChevronDown, faChevronRight, faEnvelope, faExternalLinkAlt, faHandHoldingHeart, faMicrophoneLines, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { AnimateHeightBySacrificingPerf } from 'react-anim-kit'
import { useRouter } from 'next/router'

import Button from '../Button'
import Image from '../Image'
import useViewport from '../../hooks/useViewport'

import styles from './MainNav.module.scss'

import Config from '../../config'

import breakevenLogo from '../../public/breakeven.svg'
import gamcareGLogo from '../../public/gamcare-g-logo.svg'
import flag from '../../public/flag.jpg'

const colours = ['#D11A6D', '#E2B95A', '#247B7B']

const MainNav = forwardRef(({
  isHidden,
  isFloating,
  onBlockScroll
}, ref) => {
  const [showMenu, setShowMenu] = useState(false)
  const [menuClasses, setMenuClasses] = useState('')
  const [showMenuClose, setShowMenuClose] = useState(false)
  const [selected, setSelected] = useState('')

  const viewport  = useViewport()
  const router = useRouter()

  const megaMenuRef = useRef()
  const navMenuRef = useRef()
  const navMenuToggleRef = useRef()

  useEffect(() => {
    const handleClickOutside = evt => {
      if (megaMenuRef.current && !megaMenuRef.current.contains(evt.target) && !navMenuRef.current.contains(evt.target) && !navMenuToggleRef.current.contains(evt.target)) {
        menuHide()
      }
    }
    
    document.addEventListener("mouseup", handleClickOutside)

    
    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      document.removeEventListener("mouseup", handleClickOutside)
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [])

  useEffect(() => {
    if (isHidden) {
      menuHide()
    }
  }, [isHidden])

  useImperativeHandle(ref, () => ({
    show: () => {
      menuShow()
    },
    hide: () => {
      menuHide()
    }
  }))

  const handleRouteChangeStart = () => {
    menuHide()
  }

  const menuShow = (callback, blockScroll = false) => {
    setShowMenu(true)
    setShowMenuClose(true)

    if (blockScroll && onBlockScroll) {
      onBlockScroll(true)
    }

    setTimeout(() => {
      setMenuClasses(styles.navMegaMenuShow)  

      if (callback) {
        callback()
      }
    }, 10)
  }

  const menuHide = callback => {
    setMenuClasses('')
    setShowMenuClose(false)

    if (onBlockScroll) {
      onBlockScroll(false)
    }

    setTimeout(() => {
      setShowMenu(false)

      if (callback) {
        callback()
      } else {
        setSelected('')
      }
    }, 300)
  }

  const handleOnSelect = (evt, id) => {
    if (id !== selected) {
      menuShow(() => {
        setSelected(id)
      })
    } else {
      menuHide(() => {
       setSelected('')
      })
    }
  }

  const renderMegaMenu = () => {
    if (viewport.width > 1023) {
      const item = Config.siteMap.quick.find(x => x.title === selected)

      if (item) {
        return (
          <ItemMegaMenu 
            showFooter
            items={item.items.map((x, idx) => ({
              title: x.title,
              link: x.link,
              external: x.external,
              iconColour: x.ref === 'gamcare' ? '#372580' : (idx < colours.length ? colours[idx] : colours[0]),
              image: x.ref === 'gamcare' ? gamcareGLogo : '',
              description: x.description
            }))}
          />
        )
      }

      return (
        <MegaMenu onClick={() => menuHide()} />
      )
    } else {
      return (
        <MobileMenu onClick={() => menuHide()} />
      )
    }
  }

  return (
    <>
      <div className={styles.navPlaceholder}>
        <div className={[styles.nav, isHidden ? styles.isHidden : '', isFloating ? styles.isFloating : ''].join(' ')}>
          <div className={styles.navLeft}>
            <div className={styles.navLogo}>
              <Link href="/">
                <a>
                  <Image src={breakevenLogo} alt="Breakeven" width={180} height={45} />
                </a>
              </Link>
            </div>
            <div ref={navMenuRef} className={styles.navMenu}>
              {Config.siteMap.quick.map((x, idx) => (
                <NavLink key={idx} title={x.title} onSelect={evt => handleOnSelect(evt, x.title)} />
              ))}
            </div>
          </div>
          <div className={styles.navRight}>
            <div ref={navMenuToggleRef} className={styles.navMenuToggle} onClick={() => {
              if (selected === '' && showMenu) {
                menuHide(() => {
                  setSelected('')
                  onBlockScroll(false)
                })
              } else {
                menuShow(() => {
                  setSelected('')
                }, viewport.width < 1024)
              }
            }}>
              {viewport.width < 1024 && showMenuClose ? (
                <FontAwesomeIcon icon={faTimes} width={20} />
              ) : (
                <FontAwesomeIcon icon={faBars} width={20} />
              )}
              <div className="hide-mobile">Menu</div>
            </div>
            <div className={[styles.navButtons, styles.hideDesktopSmall].join(' ')}>
              <Button icon={faPhone} link={`tel:${Config.phone}`} external />
              <Button icon={faEnvelope} link={`mailto:${Config.email}`} external />
            </div>
          </div>
          {viewport.width > 1023 &&
            <div ref={megaMenuRef} className={[styles.navMegaMenu, menuClasses].join(' ')} style={{
              display: showMenu ? 'block' : 'none'
            }}>
              {renderMegaMenu()}
            </div>
          }
        </div>
      </div>
      {viewport.width < 1024 &&
        <div ref={megaMenuRef} className={[styles.navMegaMenu, menuClasses].join(' ')} style={{
          display: showMenu ? 'block' : 'none'
        }}>
          {renderMegaMenu()}
        </div>
      }
    </>
  )
})

const NavLink = ({
  id,
  title,
  smallTitle,
  className,
  onSelect
}) => {
  return (
    <div className={[className, styles.navMenuLink].join(' ')} onClick={evt => {
      if (onSelect) {
        onSelect(evt, id)
      }
    }}>
      <div className={styles.hideDesktopSmall}>{title}</div>
      <div className={styles.showDesktopSmall}>{smallTitle || title}</div>
      <FontAwesomeIcon className={styles.hideDesktopSmall} icon={faAngleDown} />
    </div>
  )
}

const ItemMegaMenu = ({
  showFooter,
  items = []
}) => {
  return (
    <>
      <div className={styles.navItemMegaMenuContent}>
        {items.map((x, idx) => (
          <NavContentItem 
            key={idx}
            title={x.title}
            description={x.description}
            iconColour={x.iconColour}
            image={x.image}
            external={x.external}
            link={x.link}
          />
        ))}
      </div>
      {showFooter &&
        <Link href="/contact">
          <a className={styles.navMenuFooter}>
            <div>We can help, contact us now</div>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </Link>
      }
    </>
  )
}

const NavContentItem = ({
  title,
  description,
  iconColour,
  image,
  icon,
  external,
  link,
  style
}) => {
  return (
    <Link href={link || '/'}>
      <a className={styles.navItemMegaMenuContentItem} style={style} {...(external ? { target: '_blank', rel: 'noreferrer' } : {})}>
        <div className={styles.navItemMegaMenuContentItemFirst}>
          {image ? (
            <div className={styles.navItemMegaMenuContentItemImage}>
              <Image src={image} width={60} height={60} alt="" objectFit="contain" />
            </div>
          ) : (
            <div className={styles.navItemMegaMenuContentItemIcon} style={{ background: iconColour || '#D11A6D' }}>
              <FontAwesomeIcon icon={faHandHoldingHeart} />  
            </div>
          )}
          <div className={styles.navItemMegaMenuContentItemTitleSmall}>{title}</div>
        </div>
        <div className={styles.navItemMegaMenuContentItemLast}>
          <div className={styles.navItemMegaMenuContentItemTitle}>
            <div>{title}</div>
            <FontAwesomeIcon icon={icon || (external ? faExternalLinkAlt : faChevronRight)} color={iconColour || '#D11A6D'} />
          </div>
          <div className={[styles.navItemMegaMenuContentItemDescription].join(' ')}>
            <div>{description}</div>
            <FontAwesomeIcon icon={icon || (external ? faExternalLinkAlt : faChevronRight)} color={iconColour || '#D11A6D'} />
          </div>
        </div>
      </a>
    </Link>
  )
}

const MegaMenu = ({
  onClick
}) => {
  return (
    <div className={styles.navMegaMenuContent}>
      <div className={styles.navMegaMenuContentLeft}>
        {Config.siteMap.main.map((x, xIdx) => {
          return (
            <div key={xIdx} className={styles.navMegaMenuContentColumn}>
              <div className={styles.navMegaMenuContentColumnTitle}>{x.title}</div>
              {x.items.map((i, iIdx) => (
                <Link key={`${xIdx}_${iIdx}`} href={i.link}>
                  <a onClick={onClick}>
                    {i.title}
                    {i.external &&
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    }
                  </a>
                </Link>
              ))}
              {xIdx === 0 &&
                <>
                  <div className={styles.weSupport}>
                    <span className={styles.title}>We support</span>
                    <Image src={flag} alt="We support" width={53} height={30} />
                  </div>
                  <div className={styles.justGiving}>
                    <a href="https://widgets.justgiving.com/Button/Redirect?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9" target="_blank">
                      <img src="https://widgets.justgiving.com/Button?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9&v=1" alt="Just Giving" />
                    </a>
                  </div>
                </>
              }
            </div>
          )
        })}
      </div>
      <div className={styles.navMegaMenuContentRight}>
        <NavContentItem
          title="We Can Help"
          description="The aim of gambling harms treatment at Breakeven is to provide an opportunity for you to work toward increasing your well-being by becoming free of problem gambling."
          link="/gambling-harms-treatment"
          iconColour={colours[0]}
          style={{
            marginBottom: 40
          }}
        />
        <NavContentItem
          title="Self Assessment"
          description="Alongside reaching out to one of our team for specialist support, there are also lots of practical tools and resources that you may want to consider when looking at reducing gambling related harm."
          link="/self-assessment"
          iconColour={colours[1]}
        />
      </div>
    </div>
  )
}

const MobileMenu = ({
  onClick
}) => {
  const viewport = useViewport()

  const [openItem, setOpenItem] = useState('')

  const handleOnOpen = ref => {
    if (ref === openItem) {
      setOpenItem('')
    } else {
      setOpenItem(ref)
    }
  }

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.mobileMenuContent}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20
        }}>
          <Button 
            title={viewport.width > 300 ? 'PHONE' : undefined}
            icon={faPhone}
            link={`tel:${Config.phone}`}
            external
          />
          <Button 
            title={viewport.width > 300 ? 'EMAIL' : undefined}
            icon={faEnvelope}
            link={`mailto:${Config.email}`}
            external
          />
        </div>
        {/*Config.siteMap.quick.filter(x => !['steps to take', 'about us'].includes(x.title.toLowerCase())).map((x, idx) => (
          <MobileMenuSection 
            key={idx}
            title={x.title}
            items={x.items.map(i => ({
              title: i.title,
              link: i.link,
              external: i.external
            }))}
            isOpen={openItem === x.title}
            onOpen={() => handleOnOpen(x.title)}
            onClick={onClick}
          />
          ))*/}
        {Config.siteMap.main.map((x, idx) => (
          <MobileMenuSection 
            key={idx}
            title={x.title}
            items={x.items.map(i => ({
              title: i.title,
              link: i.link,
              external: i.external
            }))}
            isOpen={openItem === x.title}
            onOpen={() => handleOnOpen(x.title)}
            onClick={onClick}
          />
        ))}
        <NavContentItem
          title="We Can Help"
          description="The aim of gambling harms treatment at Breakeven is to provide an opportunity for you to work toward increasing your well-being by becoming free of problem gambling."
          link="/gambling-harms-treatment"
          iconColour={colours[0]}
          style={{
            marginTop: 30,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 30
          }}
        />
        <NavContentItem
          title="Self Assessment"
          description="Alongside reaching out to one of our team for specialist support, there are also lots of practical tools and resources that you may want to consider when looking at reducing gambling related harm."
          link="/self-assessment"
          iconColour={colours[1]}
          style={{
            margin: 0
          }}
        />
        <div className={styles.mobileMenuFooter}>
          <div className={styles.mobileMenuSocials}>
            <a href="/social/links/instagram" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="/social/links/x" target="_blank">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="/social/links/facebook" target="_blank">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="/social/links/linkedin" target="_blank">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <Link href="/podcast">
              <a>
                <FontAwesomeIcon icon={faMicrophoneLines} />
              </a>
            </Link>
          </div>
          <Image src={flag} alt="We support" width={45} height={25} />
        </div>
        <div className={styles.justGiving}>
          <a href="https://widgets.justgiving.com/Button/Redirect?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9" target="_blank">
            <img src="https://widgets.justgiving.com/Button?p=eyJJZCI6ImVhMzU3ODM4LTQxYWMtNGVjOS04Yjc4LWQ4ODZiYWYyYWU2NiIsIkNoYXJpdHlJZCI6MzMzMjI2NywiU2l6ZSI6InMiLCJSZWZlcmVuY2UiOiJ3ZWJzaXRlIiwiVHlwZSI6IkRvbmF0ZSJ9&v=1" alt="Just Giving" />
          </a>
        </div>
      </div>
      <Link href="/contact">
        <a className={styles.navMenuFooter}>
          <div>We can help, contact us now</div>
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </Link>
    </div>
  )
}

const MobileMenuSection = ({
  title,
  items = [],
  isOpen,
  onOpen,
  onClick
}) => {
  return (
    <div className={styles.mobileMenuNavSection}>
      <div className={[styles.mobileMenuNavSectionTitle, isOpen ? styles.mobileMenuNavSectionTitleOpen : ''].join(' ')} onClick={onOpen}>
        <div>{title}</div>
        <FontAwesomeIcon icon={faChevronDown} width={20} height={20} />
      </div>
      <AnimateHeightBySacrificingPerf
        shouldChange={isOpen}
        transition="height 0.3s"
      >
        {isOpen &&
          <div className={styles.mobileMenuNavSectionLinks}>
            {items.map((x, idx) => (
              <Link key={idx} href={x.link}>
                <a className={styles.mobileMenuNavSectionLink} {...(x.external ? { target: '_blank', rel: 'noreferrer' } : {})} onClick={onClick}>
                  <div>{x.title}</div>
                  <FontAwesomeIcon icon={x.external ? faExternalLinkAlt : faArrowRight} width={20} height={20} />
                </a>
              </Link>
            ))}
          </div>
        }
      </AnimateHeightBySacrificingPerf>
    </div>
  )
}

MainNav.displayName = 'MainNav'

export default MainNav