import React from 'react'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import Button from '../Button'

import styles from './ContactItem.module.scss'

const ContactItem = ({
  title,
  description,
  buttonTitle,
  icon,
  link,
  externalLink,
  backgroundColour,
  buttonIcon,
  button2Title,
  button2Icon,
  button2Link,
  button2ExternalLink,
  hideButtons
}: {
  title?: string
  description?: string
  buttonTitle?: string
  icon?: IconProp
  link?: string
  externalLink?: boolean
  backgroundColour?: string
  buttonIcon?: IconProp
  button2Title?: string
  button2Icon?: IconProp
  button2Link?: string
  button2ExternalLink?: boolean
  hideButtons?: boolean
}) => {
  return (
    <div className={styles.contactItem} style={{
      backgroundColor: backgroundColour
    }}>
      {icon &&
        <div className={styles.contactItemIcon}>
          <FontAwesomeIcon icon={icon || faPhoneAlt} />
        </div>
      }
      <div className={styles.contactItemTitle}>{title}</div>
      <div className={styles.contactItemDescription}>{description}</div>
      {!hideButtons &&
        <div className={styles.contactItemButtons}>
          <Button title={buttonTitle} icon={buttonIcon ?? icon} link={link} external={externalLink} className={styles.button} />
          {button2Link &&
            <Button title={button2Title} icon={button2Icon ?? icon} link={button2Link} external={button2ExternalLink} className={styles.button} />
          }
        </div>
      }
    </div>
  )
}

export default ContactItem